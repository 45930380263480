import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";

import {
  useCsAttribute,
  useConfigurationAttributeName,
} from "@plansoft-configuration-renderer/framework";

export function CustomAttributeHeadline(): JSX.Element {
  const attribute = useCsAttribute();

  const labelText = useConfigurationAttributeName();

  const isAllowed = attribute.isAllowed;

  return (
    <Box mt={2} mb={1} display="flex" alignItems="center">
      <FormLabel required={attribute.isRequired} disabled={!isAllowed}>
        {labelText}
      </FormLabel>
    </Box>
  );
}
