// import Button from "@mui/material/Button";
import { Suspense, ReactNode } from "react";
import { RecoilRoot } from "recoil";

import { LinearProgress } from "@mui/material";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

interface MinimalAppContextProps {
  children: ReactNode;
}

const queryClient = new QueryClient();

export function MinimalAppContext(props: MinimalAppContextProps): JSX.Element {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<LinearProgress />}>
          <MuiLocalizationProvider dateAdapter={AdapterDateFns}>
            <Suspense fallback={<LinearProgress />}>{props.children}</Suspense>
          </MuiLocalizationProvider>
        </Suspense>
      </QueryClientProvider>
    </RecoilRoot>
  );
}
